.apis-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
}

.apis-form .btn {
  width: 100%;
  max-width: 322px;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  display: flex;
  position: relative;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 78px;
  line-height: 80px;
  margin-top: 18px;
  text-decoration: none;
  border: 0px !important;
  text-align: center;
  text-decoration: none;
  color: #0048ab;
  font-family: 'Gilroy-Bold', sans-serif;
  font-weight: normal;
  font-size: 22px;
  background: #fecb1b;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px !important;
  cursor: pointer;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s ease;
}

.btn:hover {
  text-decoration: none;
  transition: all 0.3s ease;
  background: #0048ab;
  box-shadow: none;
  color: #fff;
}

.btn:focus,
.btn.focus {
  outline: none;
}

@media (max-width: 575px) {
  .btn {
    height: 61px;
    line-height: 61px;
    margin-top: 15px;
    font-size: 20px;
  }

  .apis-form {
    margin-top: 28px;
  }
}
