.logo {
  display: inline-block;
  width: 173px;
  margin-left: auto;
  margin-right: auto;
}

.logo svg {
  display: block;
  width: 100%;
  height: auto;
}