.section-contacts {
  padding-top: 33px;
  padding-bottom: 108px;
}

.contacts {
  display: block;
  width: 100%;
  max-width: 345px;
  margin-left: auto;
  margin-right: auto;
}

.contacts__item {
  display: flex;
  width: 100%;
  position: relative;
  min-height: 95px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 26px;
  padding: 24px;
  padding-top: 10px;
  padding-bottom: 14px;
  transition: all 0.3s ease;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-weight: normal;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.contacts__item.contacts__item_tg {
  padding-top: 13px;
  background: #38A0FF;
}

.contacts__item.contacts__item_tg:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 35px;
  top: calc(50% + 1px);
  transform: translateY(-50%);
  width: 43px;
  height: 36px;
  background: url(../../assets/images/tg-icon.svg) no-repeat center center/ 100% auto;
}

.contacts__item:hover {
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.25);
}

.contacts__item span {
  display: block;
  width: 100%;
  min-width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  font-family: "Gilroy-SemiBold", sans-serif;
  text-decoration: none;
}

.contacts__item.contacts__item_tg span {
  padding-left: 2px;
}

@media (max-width: 575px) {
  .section-contacts {
    padding-top: 33px;
    padding-bottom: 108px;
  }

  .contacts {
    max-width: 232px;
  }

  .contacts__item {
    min-height: 77px;
    margin-top: 13px;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 9px;
  }

  .contacts__item.contacts__item_tg {
    padding-top: 9px;
    min-height: 69px;
  }

  .contacts__item.contacts__item_tg:before {
    left: 19.5px;
    top: calc(50% + 0px);
    width: 29px;
    height: 25px;
  }

  .contacts__item span {
    font-size: 14px;
    line-height: 17px;
  }

  .contacts__item.contacts__item_tg span {
    padding-left: 0px;
  }
}