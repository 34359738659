.apis {
  display: block;
  width: 100%;
  background: #fff;
  padding: 40px;
  padding-top: 71px;
  padding-bottom: 102px;
  border-radius: 20px;
  transition: all 1s;
}

.apis h1 {
  display: block;
  width: 100%;
  text-align: center;
}

@media (max-width: 575px) {
  .apis {
    padding: 24px;
    padding-top: 31px;
    padding-bottom: 56px;
  }
}
