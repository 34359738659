.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: rgba(0, 72, 171, 0.7);
  opacity: 0;
  pointer-events: none;
  z-index: -222;
  transition: opacity 0.4s ease;
}

.overlay.active {
  cursor: pointer;
  pointer-events: auto;
  position: fixed;
  opacity: 1;
  z-index: 100;
  transition: opacity 0.4s ease;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 334px;
  max-width: calc(100% - 50px);
  background: #fff;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: -2;
  padding: 35px;
  padding-top: 38px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: opacity 0.3 ease;
}
.modal.active {
  opacity: 1;
  z-index: 101;
}

.modal h4 {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 18px;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-icon {
  display: inline-block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border: 4px solid #0048ab;
  border-right: 4px solid #fff;
  animation: 2s linear infinite 0s loading;
}

@media (max-width: 575px) {
  .modal {
    width: 268px;
    padding: 28px;
    padding-bottom: 30px;
    padding-top: 31px;
  }

  .loading-icon {
    width: 45px;
    height: 45px;
  }

  .modal h4 {
    font-size: 14px;
    line-height: 16.5px;
    margin-top: 14px;
  }
}
