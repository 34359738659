.input__list {
  display: inline-block;
  position: absolute;
  left: 0px;
  width: 100%;
  top: calc(100% - 1px);
  background: #ebebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  border: 1px solid #acacac;
  overflow: hidden;
  z-index: 4;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;
}
