.section-apis {
  padding-top: 155px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 575px) {
  .section-apis {
    padding-top: 97px;
  }
}
