.apis-result {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: -25px;
}

.result-items {
  display: block;
  width: 100%;
  max-width: 382px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.result-items:first-child {
  margin-top: 23px;
}

@media (max-width: 575px) {
  .apis-result {
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .result-items {
    margin-top: 45px;
  }

  .result-items.result-items_add {
    margin-bottom: 14px;
  }

  .result-items:first-child {
    margin-top: 25px;
  }
}
