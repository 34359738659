.number-text {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.number-text span {
  display: inline-block;
  text-align: center;
  color: #969696;
}

.input {
  display: block;
  width: 100%;
  position: relative;
  margin-top: 20px;
  max-width: 322px;
  margin-left: auto;
  margin-right: auto;
}

.input__main {
  display: block;
  width: 100%;
  position: relative;
}

.input__main::before {
  content: '';
  display: inline-block;
  width: 65px;
  height: calc(100% - 4px);
  position: absolute;
  z-index: 2;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  pointer-events: none;
  border-radius: 17px;
}

.input input {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  height: 78px;
  line-height: 78px;
  padding-left: 15px;
  padding-right: 15px;
  background: transparent;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 17px;
  font-size: 20px;
  color: #0048ab;
  font-family: 'Gilroy-Regular', sans-serif;
  font-weight: normal;
  text-align: center;
  margin-top: 0px;
  transition: all 0.3s ease;
}

.input.active input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input.disabled input {
  color: #acacac;
  cursor: not-allowed;
  user-select: none;
}

.input input::placeholder {
  font: inherit;
  font-size: inherit;
  color: inherit;
}

.input__main-close {
  display: inline-flex;
  width: 19px;
  height: 19px;
  position: absolute;
  z-index: 3;
  right: 25px;
  top: 50%;
  cursor: pointer;
  transform: translate(0%, -50%);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;
  border: 0px;
  background: #fff;
}

.input__main-close svg {
  display: inline-block;
  width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}

.input__main-close svg * {
  transition: all 0.3s ease;
}

.input__main-close:hover svg * {
  fill: #0048ab;
}

@media (max-width: 575px) {
  .input {
    margin-top: 16px;
  }

  .input input {
    height: 60px;
    line-height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
  }

  .input__main::before {
    display: none;
  }

  .input__main-close {
    display: none;
  }

  .input__list {
    top: calc(100% - 2px);
  }

  .input__list-item {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 11px;
    padding-bottom: 9px;
  }

  .input__list-item:last-child {
    padding-top: 10px;
    padding-bottom: 9px;
  }

  .input__list-item span {
    font-size: 15px;
    line-height: 18px;
  }

  .input__list-item-close {
    right: 6px;
  }

  .input__list-item-close svg {
    width: 9px;
  }
}