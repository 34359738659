.container {
  width: 700px;
  max-width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

@media (max-width: 575px) {
  .container {
    max-width: calc(100% - 22px);
  }
}
