.checkbox-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkbox-wrap {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.checkbox-wrap.hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.checkbox-wrap span {
  display: inline-block;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 1px;
  color: #969696;
}

.checkbox-wrap span span {
  display: none;
  font: inherit;
  color: inherit;
}

.checkbox-wrap span span:nth-child(1) {
  display: inline;
}

.checkbox-btn {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  margin-left: 9px;
  border: 1px solid #969696;
  border-radius: 5px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.checkbox-btn span {
  display: none;
}

.checkbox-btn::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 52%;
  width: 80%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  background-image: url(../../assets/images/checkbox-arrow.svg);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.checkbox-btn.active {
  border-color: #0048ab;
  background-color: #0048ab;
}

.checkbox-btn.active::before {
  opacity: 1;
}

@media (max-width: 575px) {
  .checkbox-wrap {
    display: block;
    margin-top: 35px;
  }

  .checkbox-wrap span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
  }

  .checkbox-wrap span span:nth-child(1) {
    display: none;
  }

  .checkbox-wrap span span:nth-child(2) {
    display: inline;
  }

  .checkbox-btn {
    display: inline-flex;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: 83px;
    height: 38px;
    padding-top: 1px;
  }

  .checkbox-btn.active {
    background: #00a98b;
    border-color: #00a98b;
  }

  .checkbox-btn::before {
    display: none;
  }

  .checkbox-btn span {
    display: block;
    width: 100%;
    min-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: auto;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .checkbox-btn.active span {
    color: #fff;
  }

  .checkbox-btn span.active {
    opacity: 1;
    position: relative;
    pointer-events: auto;
    transition: opacity 0.3s ease;
  }
}
