.question {
  display: inline-block;
  position: absolute;
  z-index: 2;
  left: calc(100% + 4px);
  top: -7px;
}

.question__icon {
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.question__icon svg {
  display: block;
  width: 100%;
  height: auto;
  margin: auto;
}

.popup {
  display: inline-block;
  position: absolute;
  left: calc(100% + 8px);
  bottom: -4px;
  width: 334px;
  background: #fff;
  padding: 22px;
  padding-top: 27px;
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  min-height: 84px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.popup:before {
  content: '';
  display: inline-block;
  position: absolute;
  opacity: 0;
  left: -11px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.popup h4 {
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 1;
}

.popup img {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 19.5px;
}

.question:hover .popup {
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 575px) {
  .popup {
    width: 214px;
    right: 6px;
    padding: 15px;
    padding-top: 13px;
    border-radius: 10px;
    min-height: 53px;
  }

  .popup img {
    margin-top: 14px;
  }
}

@media (max-width: 1199px) {
  .popup {
    width: 262px;
    max-width: calc(100vw - 106px);
    right: 0px;
    left: auto;
    bottom: calc(100% + 6px);
  }

  .popup:before {
    left: 0px;
    top: auto;
    bottom: -6px;
  }
}
